<template>
  <div>
    <b-modal
      no-close-on-backdrop
      v-model="show"
      id="modal-center"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closeAdminPopUp" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
          <h3 class="app-title lg">
            {{ action === "add-new" ? "Add Admin" : "Edit Admin" }}
          </h3>
          <validation-observer ref="consultantForm" #default="{ invalid }">
            <div class="form-fields mt-3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <label for="client-name">Admin Name</label>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="admin.name"
                      id="client-name"
                      placeholder="Enter full name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <label for="client-name">Admin Email</label>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="admin.email"
                      id="client-name"
                      placeholder="Enter email address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div v-if="action === 'edit'">
                <b-form-checkbox
                  v-model="admin.active"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Active
                </b-form-checkbox>
              </div>

              <b-button
                @click="processData"
                :disabled="isBusy"
                class="py-1 mt-2"
                block
                variant="primary"
              >
                <b-spinner v-if="isBusy" small class="mr-1" />
                {{
                  action === "add-new" ? "Create Admin" : "Save Changes"
                }}</b-button
              >
            </div>
          </validation-observer>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BCardText,
  BButton,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import axios from "axios";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    consultant: {
      type: Object,
      default: () => {
        return {
          _id: "",
          name: "",
          email: "",
          city: "",
          sector: null,
        };
      },
    },
    admin: {
      type: Object,
      default: () => {
        return {
          _id: "",
          name: "",
          email: "",
          status: "",
        };
      },
    },
    action: {
      type: String,
      required: true,
    },
  },
  components: {
    BModal,
    BCardText,
    BButton,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      sectors: [
        { value: null, text: "Select Sector", disabled: true },
        "CA",
        "Software Company",
        "CodeRenowned",
      ],
      required,
      email,
      isBusy: false,
    };
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    closeAdminPopUp() {
      this.$emit("closeAdminPopUp", true);
    },
    processData() {
      this.$refs.consultantForm.validate().then((success) => {
        if (success) {
          if (this.action === "edit") {
            this.updateAdmin(this.admin);
          } else if (this.action === "add-new") {
            let admin = this.admin;
            delete admin._id;
            this.addNew(admin);
          }
        }
      });
    },
    updateAdmin(admin) {
      this.$set(this, "isBusy", true);
      const { _id, name, email, active } = admin;
      const token = this.$getUserToken();
      axios
        .put(
          `${process.env.VUE_APP_SERVER_URL}/admin`,
          {
            _id,
            name,
            email,
            active,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.$set(this, "isBusy", false);
          this.$emit("updated", true);
          this.showToast(
            "Updated",
            "The admin data has been updated successfully!",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.$set(this, "isBusy", false);
          this.showToast(
            "Error",
            "Error updating the admin! Please try again later.",
            "danger"
          );
        });
    },
    addNew(admin) {
      this.$set(this, "isBusy", true);
      let token = this.$getUserToken();

      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/admin`, admin, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$set(this, "isBusy", false);
          this.$emit("added", true);
          this.showToast(
            "Created",
            "An admin has been created successfully!",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.$set(this, "isModalBusy", false);
          this.showToast(
            "Error",
            "Error creating the admin! Please try again later",
            "danger"
          );
        });
    },
  },
};
</script>

<style></style>
