<template>
  <div>
    <b-card>
      <b-card-title>
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <div
            class="text-left order-2 mt-1 mt-md-0 order-md-1 w-100 w-md-auto"
          >
            <div class="d-flex w-100 w-md-auto align-items-center">
              <b-form-group class="mb-0 w-md-auto">
                <label class="text-sm-left">Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    @change="checkEmptySearchInput"
                    id="filterInput"
                    v-model="search"
                    type="search"
                    placeholder="Enter Name / Email"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="searchConsultants"
                      :disabled="!search"
                    >
                      Search
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="text-right order-1 order-md-2 w-100 w-md-auto">
            <b-button @click="initNewConsultantAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>
              Add New</b-button
            >
          </div>
        </div>
      </b-card-title>
      <b-table
        @row-clicked="onRowClicked"
        :busy="isBusy"
        :items="admins"
        hover
        :fields="fields"
        responsive
        show-empty
      >
        <template #empty="scope">
          <div class="text-center">
            <p>0 consultants found!</p>
            <b-button @click="initNewConsultantAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>

              Add New
            </b-button>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner variant="primary" class="align-middle"></b-spinner>
            <strong class="ml-1">Loading consultants...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <b-avatar rounded size="32" variant="light-company">
              <b-img
                :src="data.item.avatar ? data.item.avatar : defaultAvatar"
                alt="avatar img"
            /></b-avatar>
            <div>
              <div class="font-weight-bolder">
                {{ data.item.name }}
                <b-badge
                  v-b-tooltip.hover.top="'Pending account activation!'"
                  v-if="!data.item.superAdmin && !data.item.activated"
                  class="font-weight-light"
                  size="sm"
                  variant="light-warning"
                  ><feather-icon icon="ClockIcon"></feather-icon>
                </b-badge>
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.sector }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-left">
            <b-badge v-if="data.item.superAdmin" variant="success"
              >Active</b-badge
            >
            <b-badge v-else-if="data.item.active" variant="success"
              >Active</b-badge
            >
            <b-badge v-else variant="danger">Inactive</b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-b-tooltip.hover.top="'Edit Admin'"
              size="sm"
              @click="initEditAdmin(data)"
              variant="outline-primary"
              class="btn-icon"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-footer>
        <b-row>
          <b-col cols="3">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                @change="sectorChanged"
                id="perPageSelect"
                v-model="limit"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="offset-3">
            <b-pagination
              @change="paginate"
              class="justify-content-end"
              v-model="page"
              :per-page="limit"
              :total-rows="totalAdmins"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <CreateAdminModal
      :isBusy="isModalBusy"
      :admin="admin"
      :show="showCreateAdminModal"
      :action="action"
      @closeAdminPopUp="closeAdminPopUp"
      @added="addedNew"
      @updated="updatedConsultant"
    />
  </div>
</template>

<script>
import {
  BTable,
  BCardTitle,
  BCardFooter,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BImg,
  VBTooltip,
  BFormSelect,
} from "bootstrap-vue";
import axios from "axios";
import CreateConsultantModal from "@/components/consultant/CreateConsultantModal.vue";
import CreateAdminModal from "@/components/Admin/CreateAdminModal.vue";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    CreateConsultantModal,
    CreateAdminModal,
    BPagination,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BImg,
    BFormSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: "index", label: "S.No" },
        { key: "name", label: "Name" },
        "email",
        "status",
        "actions",
      ],
      defaultAvatar:
        "https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/toolbox.2bbf519b.svg",
      admins: [],
      admin: {
        _id: "",
        name: "",
        email: "",
      },
      showCreateAdminModal: false,
      limit: 10,
      page: 1,
      search: "",
      totalAdmins: 0,
      isBusy: true,
      isModalBusy: false,
      action: "add-new",
      pageOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    closeAdminPopUp() {
      this.showCreateAdminModal = false;
      this.resetAdminModal();
    },
    initNewConsultantAdd() {
      this.$set(this, "showCreateAdminModal", true);
    },
    resetPagination() {
      this.$set(this, "page", 1);
      this.$set(this, "search", "");
    },
    resetAdminModal() {
      this.$set(this, "admin", {
        _id: "",
        name: "",
        email: "",
      });
      this.$set(this, "action", "add-new");
    },
    addedNew() {
      this.$set(this, "showCreateAdminModal", false);
      this.resetPagination();
      this.resetAdminModal();
      this.getAdmins();
    },
    updatedConsultant() {
      this.$set(this, "showCreateAdminModal", false);
      this.resetAdminModal();
      this.getAdmins();
    },
    getAdmins() {
      this.isBusy = true;
      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/admin?limit=${this.limit}&page=${this.page}&search=${this.search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.admins = response.data.admins;
          this.totalAdmins = response.data.totalAdmins;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
    onRowClicked(item) {
      return;
    },
    initEditAdmin(data) {
      let admin = data.item;
      this.$set(this, "admin", admin);
      this.$set(this, "showCreateAdminModal", true);
      this.$set(this, "action", "edit");
    },
    checkEmptySearchInput(value) {
      if (!value) {
        this.resetPagination();
        this.getAdmins();
      }
    },
    sectorChanged() {
      this.$set(this, "page", 1);
      this.getAdmins();
    },
    paginate(value) {
      this.$set(this, "page", value);
      this.getAdmins();
    },
    searchConsultants() {
      this.$set(this, "page", 1);
      this.getAdmins();
    },
  },
  mounted() {
    this.getAdmins();
  },
};
</script>
